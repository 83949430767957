@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
} */

.backgroundImage {
  background-image: url(../src/assets/img/background.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.slick-prev {
  width: 50px;
  left: 0;
  top: 40%;
  position: absolute;
  height: 50px;
  background-color: #002e66;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.slick-next {
  width: 50px;
  right: 0;
  top: 40%;
  position: absolute;
  height: 50px;
  background: #002e66;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.remove-srollbar ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.remove-srollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  width: 8px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: #002e66 !important;
}
::-webkit-scrollbar-track {
  background-color: transparent !important;
}
